<template>
  <div class="panel">
    <div class="panel-item">
      <header>Бүртгүүлэх</header>
    </div>
    <div class="panel-item">
      <el-form
        @submit.native.prevent="onSubmit"
        class="login-form"
        :model="model"
        :rules="rules"
        ref="form"
      >
        <el-form-item prop="username" label="Нэвтрэх нэр:">
          <el-input
            v-model="model.username"
            placeholder="Нэвтрэх нэр"
            prefix-icon="fas fa-user"
          ></el-input>
        </el-form-item>
        <el-form-item prop="email" label="И-мэйл:">
          <el-input
            v-model="model.email"
            placeholder="И-мэйл"
            type="email"
            prefix-icon="fas fa-user"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" label="Нууц үг:">
          <el-input
            v-model="model.password"
            placeholder="Нууц үг"
            type="password"
            prefix-icon="fas fa-lock"
          ></el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword" label="Нууц үг давтах">
          <el-input
            v-model="model.confirmPassword"
            placeholder="Нууц үг"
            type="password"
            prefix-icon="fas fa-lock"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            class="login-button block"
            type="primary"
            native-type="submit"
            @click="onSubmit()"
            >Бүртгүүлэх</el-button
          >
        </el-form-item>
        <div class="text-center">
          <router-link class="inventory" to="/signIn">Нэвтрэх</router-link>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { signUp } from '../../../utils/auth.js'
export default {
  name: 'signUp',
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'))
      } else {
        if (this.model.confirmPassword !== '') {
          this.$refs.form.validateField('confirmPassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else if (value !== this.model.password) {
        callback(new Error('Two inputs don\'t match!'))
      } else {
        callback()
      }
    }
    return {
      validCredentials: {
        username: 'lightscope',
        email: 'lightscope',
        password: 'lightscope'
      },
      model: {
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
      },
      loading: false,
      rules: {
        username: [
          {
            required: true,
            message: 'Нэвтхэр нэр хоосон байна',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: 'Email is required', trigger: 'blur' }
        ],
        password: [
          { validator: validatePass, trigger: 'blur' },
          {
            min: 8,
            message: '8 тэмдэгтээс богино байна',
            trigger: 'blur'
          }
        ],
        confirmPassword: [
          { validator: validatePass2, trigger: 'blur' },
          {
            min: 8,
            message: '8 тэмдэгтээс богино байна',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          signUp(this.model.username, this.model.email, this.model.password).then((response) => {
            this.loading = false
            this.$router.push({
              name: 'verify',
              params: {
                username: response.user.username
              }
            })
          })
        }
      })
    }
  }
}
</script>
